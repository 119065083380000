import React, { useEffect, useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";

import { ToastMessage } from "../../Components/Toast";
import { getDefaultMaxMin, setDefaultMaxMin } from "../Service";
import "../style.css";
import axios from "axios";
import { BASE_URL } from "../../utils/config";
import toast from "react-hot-toast";
import { AXIOS_INSTANCE } from "../../service";
export default function CreateAnnouncement() {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [error, setError] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [text, setText] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const handleCloseToast = () => setShowToast(false);
  const handleShowToast = () => setShowToast(true);

  const handleDateChange = (e) => {
    const date = new Date(e.target.value);
    const formattedDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
    setFromDate(formattedDate);
  };

  const handleToDateChange = (e) => {
    const date = new Date(e.target.value);
    const formattedDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
    setToDate(formattedDate);
  };

  const onSubmitHandler = async () => {
    try {
      const {
        data: { response, code },
      } = await AXIOS_INSTANCE.post(
        `${BASE_URL}/admin/announcement`,
        {
          active: isActive,
          announcement: text,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      toast.success("Announcement created successfully");
      if (code == 200) {
        window.location.href = "/dashboard/announcements";
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <div id="addBanner">
        <div class="page-heading">
          <h3>Create Announcement</h3>
        </div>
        <div class="page-content">
          <div class="row" id="basic-table">
            <div class="col-12">
              <div class="card">
                <div class="card-content">
                  <div class="card-body">
                    <div class="row"></div>
                    <div className="event_body series_value_set">
                      <Row>
                        <Col md={6}>
                          <Form>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicPassword"
                            >
                              <Form.Label>Create Announcement</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter text"
                                name="min"
                                onChange={(e) => setText(e.target.value)}
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>From</Form.Label>
                              <form>
                                <input
                                  type="date"
                                  onChange={handleDateChange}
                                />
                              </form>
                            </Form.Group>

                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>To</Form.Label>
                              <form>
                                <input
                                  type="date"
                                  onChange={handleToDateChange}
                                />
                              </form>
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Is Active</Form.Label>
                              <Form.Switch
                                type="checkbox"
                                label="Active"
                                onChange={(e) => setIsActive(e.target.checked)}
                              />
                            </Form.Group>
                            {error && <p className="text-danger">{error}</p>}
                            <Button variant="primary" onClick={onSubmitHandler}>
                              Save
                            </Button>
                          </Form>
                        </Col>
                        <Col md={6}></Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastMessage
        show={showToast}
        close={handleCloseToast}
        message={toastMessage}
        type="Success"
        title=""
      />
    </>
  );
}
