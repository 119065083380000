//entrypoint
import axios from "axios";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const generateFingerprint = async () => {
  const fingerprint = await FingerprintJS.load();
  const result = await fingerprint.get();
  const visitorId = result.visitorId;
  return visitorId;
};
export const AXIOS_INSTANCE_WITHOUT_INTERCEPTOR = axios.create();

AXIOS_INSTANCE_WITHOUT_INTERCEPTOR.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers["x-origin"] = "56ea6c9482b7e0656b56ef6f8aa3836a";
  config.headers["x-browser-fingerprint"] = await generateFingerprint();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
AXIOS_INSTANCE_WITHOUT_INTERCEPTOR.interceptors.response.use(
  (response) => {
    const token = localStorage.getItem("token");
    if (response.headers.authorization && token) {
      localStorage.setItem(
        "token",
        response?.headers?.authorization.replace("Bearer", "")
      );
    }
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
      window.location.reload();
    }
    return error;
  }
);

export const AXIOS_INSTANCE = AXIOS_INSTANCE_WITHOUT_INTERCEPTOR;
