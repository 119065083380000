import React, { useEffect, useState } from "react";
import { Col, Form, Row, Button, FormCheck } from "react-bootstrap";

import { ToastMessage } from "../../Components/Toast";
import "../style.css";
import { BASE_URL } from "../../utils/config";
import { AXIOS_INSTANCE } from "../../service";

export default function Announcements() {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [data, setData] = useState([]);
  // const [viewList, setViewList] = useState([]);
  const handleCloseToast = () => setShowToast(false);
  const handleShowToast = () => setShowToast(true);
  const token = localStorage.getItem("token");
  const announcementList = async () => {
    try {
      const { data } = await AXIOS_INSTANCE.get(
        `${BASE_URL}/admin/announcements`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(data.response);
      console.log(data.response, "announcement");
    } catch (error) {
      console.error(error);
    }
  };

  const deleteAnnouncement = async (id) => {
    try {
      const response = await AXIOS_INSTANCE.delete(
        `${BASE_URL}/admin/announcement/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      announcementList();
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const manageStatus = async (id, status) => {
    try {
      const response = await AXIOS_INSTANCE.put(
        `${BASE_URL}/admin/announcement`,
        {
          announcementId: id,
          active: status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      announcementList();
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  useEffect(() => {
    announcementList();
  }, []);

  return (
    <>
      <div className="page-heading">
        <h3>Announcements</h3>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <a href="/dashboard/create-announcement">
              <button className="btn btn-primary right">Add</button>
            </a>
            <table className="table table-image">
              <thead className="text">
                <tr>
                  <th scope="col">S. No.</th>
                  <th scope="col">Announcements</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((row, index) => (
                  <tr
                    style={{
                      color: "white",
                    }}
                  >
                    <th>{index + 1}</th>
                    <th>{row.announcement}</th>
                    <td>
                      {/*  <button className="btn btn-primary right">Edit</button> */}
                      <button
                        className="btn btn-primary right"
                        onClick={() => deleteAnnouncement(row.announcementId)}
                      >
                        Delete
                      </button>

                      <Form.Switch
                        type="switch"
                        id="custom-switch"
                        defaultChecked={row.active}
                        onChange={(e) => {
                          manageStatus(row.announcementId, e.target.checked);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <ToastMessage
        show={showToast}
        close={handleCloseToast}
        message={toastMessage}
        type="Success"
        title=""
      />
    </>
  );
}
