import axios from "axios";
import { PORT } from "../utils/port";
import { JSEncrypt } from "jsencrypt";
import { BASE_URL } from "../utils/config";
import { AXIOS_INSTANCE } from "../service";
export const PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
MIIBITANBgkqhkiG9w0BAQEFAAOCAQ4AMIIBCQKCAQB0ZacT13Ok+3pX0lmuJ9dJ
F6atV7Vwv5gmgpvXiBVYKKM4sfGb2qg395uVcampRgLrM63KxElPkWaa90j10ol6
fnHneE3sGuNIrvUmSvtqgeSD8nS4GXdkDecD+HSB7OCOcr+oxsGZzXJdzN/5QyUd
DGpRTlm8T+yB6wa9sJPPgZJ/C650JF57WF+G3qSngqJZEtGrKLlZYL24AqVEg85d
gLsrawWpZUEGeYqhYsdSeoGqkAR6E8Ybw+YWEYCFyMLZi2Fe5MVwMARWQ5kzpKTx
0ur7DPsghrAem08DIAekAN97F1DS48Vs88T29C2716ylE27ICyiMLDw9DLXekhAD
AgMBAAE=
-----END PUBLIC KEY-----`;
var ENCRYPT = new JSEncrypt();
ENCRYPT.setPublicKey(PUBLIC_KEY);
export const Encrypt = (data) => ENCRYPT.encrypt(data);
export const userLogin = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/auth/signin`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
