import axios from "axios";
import isEmpty from "is-empty";
import React, { createContext, useCallback, useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";

import LocalStorageService from "../utils/LocalStorageService";

const UserContext = createContext();

export function useUser() {
  return useContext(UserContext);
}

export default function UserProvider({ children }) {
  const [userData, setUserData] = useState({});

  const [userRole, setUserRole] = useState();
  const [userAccountDetails, setUserAccountDetails] = useState({});
  const [isDataLoading, setDataLoading] = useState(false);

  const onSubmitUserHandler = (data) => {
    setUserData(data);

    LocalStorageService.setItem("user", data);
    console.log(data, "findData");
    // getUserDetailsByUserNameHanlder(data.username);
    //  getUserDetailsByUserNameHanlder(data.username);
  };

  //   useEffect(() => {
  //     if (!isEmpty(userData)) {
  //       getUserDetailsByUserNameHanlder(userData.username);
  //     }
  //   }, [userData]);

  //   const getUserDetailsByUserNameHanlder = async (username) => {
  //     const res = await getUserDetailsByUserName(username);

  //     if (res.code === 200) {
  //       setUserAccountDetails(res.response);
  //       setUserRole(res.response.userType);
  //     }

  //     console.log(res, "resUser");
  //   };

  const setUserAccountDetailsHandler = useCallback((data) => {
    setUserAccountDetails(data);
  }, []);

  useEffect(() => {
    const user = LocalStorageService.getItem("user");
    console.log(user, "testing");

    if (user) {
      if (!isEmpty(user)) {
        if (user?.roles) {
          for (const role of user?.roles) {
            setUserRole(role.roleType);
          }
        }
        setUserData(user);
      }
    }
  }, []);

  const values = {
    userData,
    onSubmitUserHandler,
    userRole,
    setUserAccountDetailsHandler,
    userAccountDetails,
    isDataLoading,
    setDataLoading,
  };
  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
}
