import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { IoHomeOutline, IoPersonCircle } from "react-icons/io5";
import { useUser } from "../Context/UserProvider";
import Sidebar from "./Sidebar";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  updateEventCricket,
  updateEventSoccer,
  updateEventTennis,
} from "../Components/layout/Service";

/**
 * @author
 * @function Navigation
 **/

export const Navigation = (props) => {
  const { userData, setDataLoading } = useUser();

  const location = useLocation();
  const history = useHistory();
  const [sport, setSport] = useState("cricket");

  const sportChangeHandler = (e) => {
    history.push(`/dashboard/event-management/${e.target.value}`);
  };

  const updateEventHandler = async (sport) => {
    let res;
    setDataLoading(true);
    if (sport === "cricket") {
      res = await updateEventCricket();
    } else if (sport === "tennis") {
      res = await updateEventTennis();
    } else if (sport === "soccer") {
      res = await updateEventSoccer();
    }
    setDataLoading(true);
    window.location.href = "/";
    console.log(res, "resData");
  };

  const sportParam = location.pathname.includes("horse-racing")
    ? "horse-racing"
    : location.pathname.includes("cricket")
    ? "cricket"
    : location.pathname.includes("tennis")
    ? "tennis"
    : location.pathname.includes("soccer")
    ? "soccer"
    : location.pathname.includes("t10")
    ? "t10"
    : null;

  useEffect(() => {
    setSport(sportParam);
  }, [sportParam]);

  return (
    <>
      <Navbar bg="dark" variant="dark" className="w-100 justify-content-end">
        <Container fluid>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto w-100 justify-content-between">
              <div
                className="d-flex align-items-center justify-content-between"
                style={{ width: 200 }}
              >
                <Nav.Link
                  style={{ fontSize: 30 }}
                  href="/dashboard/event-management/cricket"
                >
                  {" "}
                  <IoHomeOutline />
                </Nav.Link>
                <fieldset class="form-group ml-5 mb-0">
                  <select
                    class="form-select"
                    id="basicSelect"
                    onChange={sportChangeHandler}
                    value={sport}
                  >
                    <option value="-1">Select sport</option>
                    <option value="cricket">Cricket</option>

                    <option value="tennis">Tennis</option>
                    <option value="soccer">Soccer</option>
                  </select>
                </fieldset>
              </div>

              <Nav.Link href="#">
                {sportParam !== "horse-racing" && (
                  <button
                    className="btn btn-primary"
                    onClick={() => updateEventHandler(sportParam)}
                  >
                    Update Event
                  </button>
                )}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
        <div className="mobile-sidebar">
          <Sidebar />
        </div>
      </Navbar>
    </>
  );
};
