import { getToken } from "../../Dashboard/Service";
import { AXIOS_INSTANCE } from "../../service";
import {
  CRICKET_BASE_URL,
  TENNIS_BASE_URL,
  SOCCER_BASE_URL,
  BASE_URL,
} from "../../utils/config";

export const searchMarket = async (matchId, market) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_BASE_URL}/all-markets/${market}?cricketEventId=${matchId}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const searchEvent = async (market) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_BASE_URL}/all-matches-admin/search?search=${market}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const searchEventVirtual = async (market) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_BASE_URL}/all-virtual-matches-admin/search?search=${market}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const searchEventSoccer = async (market) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${SOCCER_BASE_URL}/all-matches-admin/search?search=${market}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const searchMarketSoccer = async (matchId, market) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${SOCCER_BASE_URL}/all-markets/${market}?id=${matchId}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const searchMarketTennis = async (matchId, market) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${TENNIS_BASE_URL}/all-markets/${market}?id=${matchId}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const searchEventTennis = async (market) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${TENNIS_BASE_URL}/all-matches-admin/search?search=${market}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const updateEventCricket = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_BASE_URL}/events/all`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const updateEventSoccer = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(`${SOCCER_BASE_URL}/events/all`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return data;
  } catch (err) {
    return err;
  }
};
export const updateEventTennis = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(`${TENNIS_BASE_URL}/events/all`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return data;
  } catch (err) {
    return err;
  }
};
export const getUserDataAPI = async (userId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/accmanage/user/${userId}`
    );
    return data;
  } catch (error) {
    return error;
  }
};
