import isEmpty from "is-empty";
import React, { useCallback, useEffect, useState } from "react";
import { FloatingLabel, Form, Table } from "react-bootstrap";
import { useParams } from "react-router";
import { ConfirmModal } from "../Components/Modal/ConfirmModal";
import { CustomModal } from "../Components/Modal/CustomModal";
import { ToastMessage } from "../Components/Toast";
import Pagination from "react-js-pagination";
import axios from "axios";

import {
  allMarketsSoccer,
  allMarketsTennis,
  marketBetEnableDisabledSoccer,
  marketBetEnableDisabledTennis,
  matchBetEnableDisabledSoccer,
  matchBetEnableDisabledTennis,
  matchEnableDisabledSoccer,
  matchEnableDisabledTennis,
  setMarketMaxMinBetSoccer,
  setMarketMaxMinBetTennis,
  setMatchMaxMinBetSoccer,
  setMatchMaxMinBetTennis,
  setSeriesMaxMinBetSoccer,
  setSeriesMaxMinBetTennis,
  settleMarketFancySoccer,
  settleMarketFancyTennis,
  settleMarketSoccer,
  settleMarketTennis,
} from "./Service";
import { useAllMarket } from "../Context/AllMarketProvider";
import { SOCCER_BASE_URL, TENNIS_BASE_URL } from "../utils/config";
import toast from "react-hot-toast";
import { AXIOS_INSTANCE } from "../service";
const marketNameTemp = "MATCH_ODDS";

export default function AllMarketDataComponentSoccer(props) {
  const { eventId, matchId, matchName } = useParams();
  const { allMarketData, onSubmitAllMarketData } = useAllMarket();
  const [isSeriesSetPrice, setIsSeriesSetPrice] = useState(false);
  const [showConfrimModal, setShowConfirmModal] = useState(false);
  const [isMatchSetPrice, setIsMatchSetPrice] = useState(false);
  const [editBetData, setEditBetData] = useState({});
  const [showEditBetModal, setShowEditBetModal] = useState(false);
  const [isMarketBetLockStatus, setIsMarketBetLockStatus] = useState(false);
  const [isMatchBetLockStatus, setIsMatchBetLockStatus] = useState(false);
  const [isMarketEnableStatus, setIsMarketEnableStatus] = useState(false);
  const [isBetLockStatus, setIsBetLockStatus] = useState(false);
  const [showConfrimMatchModal, setShowConfirmMatchModal] = useState(false);
  const [confirmMatchDetails, setConfirmMatchDetails] = useState("");
  const [showMarketSettelModal, setShowMarketSettelModal] = useState(false);
  const [settelmentData, setSettelmentData] = useState({});
  const [marketSettlmentType, setMarketSettlmentType] = useState("");
  const [selectedMatchId, setSelectedMatchId] = useState("");
  const [errorMessage, setErroreMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [winnerData, setWinnerData] = useState({});
  const [matchRestult, setMatchResult] = useState("");
  const [isSessionPassed, setIsSessionPassed] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [matchResultType, setMatchResultType] = useState({
    abandoned: false,
    tie: false,
    finalResult: false,
  });
  const [finalResult, setFinalResult] = useState(0);

  const handleCloseEditBetModal = () => setShowEditBetModal(false);
  const handleCloseConfirmMatchModal = () => setShowConfirmMatchModal(false);
  const handleOpenConfirmMatachModal = () => setShowConfirmMatchModal(true);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleChangeRadio = (e, type) => {
    const { name, checked } = e.target;
    setMatchResultType({ [type]: checked });
  };

  const handleChangeFinalResult = (e) => {
    setFinalResult(e.target.value);
  };

  const handleOpenEditBetModal = (id, type) => {
    setIsSeriesSetPrice(false);
    setIsMatchSetPrice(false);
    setEditBetData((prevState) => ({ ...prevState, marketId: id }));

    setShowEditBetModal(true);
  };
  const handleCloseToast = () => setShowToast(false);
  const handleShowToast = () => setShowToast(true);
  const handleCloseMarketSettelModal = () => setShowMarketSettelModal(false);
  const handleOpenMarketSettelModal = (data, eventId, type) => {
    setShowMarketSettelModal(true);
    setSettelmentData((prev) => ({ ...prev, ...data, eventId }));
    setMarketSettlmentType(type);
    setSelectedMatchId(matchId);
  };

  const allMarketsHandler = useCallback(async () => {
    const res = await allMarketsSoccer(matchId, +activePage - 1);

    if (res.code === 200) {
      onSubmitAllMarketData(res.response.content);
      setTotalPageCount(res.response.totalElements);
    }
  }, [activePage, matchId]);

  useEffect(() => {
    allMarketsHandler();
  }, [allMarketsHandler, activePage]);

  const handleSwitchChange = (e, data, type) => {
    e.stopPropagation();
    if (type === "Market_BetStatus") {
      setIsMarketBetLockStatus(true);
      setIsMatchBetLockStatus(false);
      setIsMarketEnableStatus(false);
      setIsBetLockStatus(false);
      confirmStatusUpdate(data, "MARKET");
      handleOpenConfirmMatachModal();
    }
    if (type === "Market_Enable") {
      AXIOS_INSTANCE.put(
        `${SOCCER_BASE_URL}/market-enable-disable?marketId=${
          data.id
        }&flag=${!data.enabled}`
      ).then((res) => {
        allMarketsHandler();
        console.log(res);
      });
    }
  };

  const confirmStatusUpdate = (data, type) => {
    console.log(data, "checkStatus");
    if (type === "MARKET") {
      setConfirmMatchDetails((prevState) => ({
        ...prevState,
        confirmValue: data.enabled ? "Enabled Bet" : "Disabled Bet",
        matchName: data.eventName,
        marketId: data.id,
        betEnable: !data.betLock,
      }));
    }
  };

  const matchEnableDisabledHandler = async () => {
    const { isEnabled, matchId, betEnable, marketId } = confirmMatchDetails;

    let res;
    if (isMatchBetLockStatus) {
      res = await matchBetEnableDisabledSoccer(matchId, betEnable);
    } else if (isMarketBetLockStatus) {
      res = await marketBetEnableDisabledSoccer(marketId, betEnable);
    } else {
      res = await matchEnableDisabledSoccer(matchId, isEnabled);
    }

    handleCloseConfirmMatchModal();
    allMarketsHandler();

    console.log(res, "resConfirm");
  };

  const onSubmitBetHandler = async () => {
    const { maxMarket, minMarket, marketId, seriesId, matchId } = editBetData;
    if (parseInt(minMarket) > parseInt(maxMarket)) {
      setErroreMessage("Invalid input!");
      return;
    } else {
      setErroreMessage("");
    }
    let res;

    if (isSeriesSetPrice) {
      res = await setSeriesMaxMinBetSoccer(maxMarket, minMarket, seriesId);
    } else if (isMatchSetPrice) {
      res = await setMatchMaxMinBetSoccer(maxMarket, minMarket, matchId);
    } else {
      res = await setMarketMaxMinBetSoccer(maxMarket, minMarket, marketId);
    }

    if (res.code === 200) {
      handleShowToast();

      handleCloseEditBetModal();
    }

    console.log(res, "resStatus");
  };

  const handleMarketEditChange = (e) => {
    const { name, value } = e.target;
    setErroreMessage("");

    setEditBetData((prevState) => {
      console.log(prevState, "checkPRev");
      return { ...prevState, [name]: value };
    });
  };

  const settleMarketHandler = async () => {
    const { marketName, eventId, marketId, comment } = settelmentData;
    const { selectionId, winnerName } = winnerData;

    //settle or rollback

    const payload = {
      eventId,
      marketId: settelmentData.id,
      marketName,
      settlementComment: comment,
      winnerName: winnerName,
      winnerSelectionId: selectionId,
      eventType: marketSettlmentType,
      isSessionPassed: true,
      abandoned: false,
      tie: false,
    };
    if (!isEmpty(matchRestult)) {
      payload[matchRestult] = true;
      delete payload.winnerName;
    }

    if (!marketName.includes(marketNameTemp)) {
      payload.isSessionPassed = isSessionPassed;
      delete payload.winnerName;
      delete payload.winnerSelectionId;
    }

    const res = await settleMarketSoccer(payload);
    console.log(res.response, "resData");
    if (!isEmpty(res.response)) {
      handleShowToast();

      toast.success("Market Settled Successfully");
      allMarketsHandler();
    }
    handleCloseMarketSettelModal();
    handleShowToast();
    setToastMessage(res.message);
    setSettelmentData({});
    setSettelmentData({});
    setIsSessionPassed(false);
    //selectedMatchId
    // allMarketsHandler(selectedMatchId);
    const tempData = [...allMarketData];
    for (const data of tempData) {
      console.log(data, settelmentData.id, "checkDAta");
      if (data.id === settelmentData.id) {
        data.isMarketSettled = !data.isMarketSettled;
      }
    }
    onSubmitAllMarketData(tempData);
  };

  const handleChangeWinner = (e) => {
    const { value } = e.target;
    console.log(value);
    if (value === "abandoned" || value === "tie") {
      return setMatchResult(value);
    } else {
      setMatchResult("");
    }
    const selectionId = value.split("-")[0];
    var winnerName = value.split("-")[1];
    if (value.split("-").length > 2) {
      winnerName = value.split("-").slice(1).join("-");
    }
    setWinnerData((prev) => ({
      ...prev,
      selectionId: selectionId,
      winnerName,
    }));
  };

  const handleSwitchSettelChange = (e) => {
    e.stopPropagation();
    setIsSessionPassed(e.target.checked);
  };

  const handleChangeSettel = (e) => {
    const { name, value } = e.target;
    setSettelmentData((prevState) => ({ ...prevState, [name]: value }));
  };

  const settleMarketFancyHandler = async () => {
    // if abandoned finalScore will not

    if (
      settelmentData.marketName === "Match Odds-BookMaker 0% Comm.-M" ||
      settelmentData.marketName === "MATCH_ODDS"
    ) {
      return settleMarketHandler();
    }

    const payload = {
      abandoned: matchResultType.abandoned ? true : false,
      eventId: eventId,
      eventType: marketSettlmentType,
      marketId: settelmentData.marketId,
      marketName: settelmentData.marketName,
      settlementComment: settelmentData.comment,
      tie: matchResultType.tie ? true : false,
    };

    if (matchResultType.finalResult) {
      payload.finalScore = finalResult;
    }
    if (marketSettlmentType === "rollback") {
      payload.abandoned = false;
      payload.tie = false;
      payload.finalScore = null;
    }
    const res = await settleMarketFancySoccer(payload);
    console.log(res, "resData");
    handleCloseMarketSettelModal();
    handleShowToast();
    setToastMessage(res.message);
    setSettelmentData({});
    setSettelmentData({});
    setIsSessionPassed(false);
    //selectedMatchId
    // allMarketsHandler(selectedMatchId);

    const tempData = [...allMarketData];
    for (const data of tempData) {
      console.log(data, settelmentData, "checkDAta");
      if (data.id === settelmentData.id) {
        data.isMarketSettled = !data.isMarketSettled;
      }
    }
    onSubmitAllMarketData(tempData);
  };

  console.log(settelmentData, "settelmentData");

  return (
    <>
      <div class="page-heading">
        <h3>{matchName}</h3>
      </div>
      <Table striped bordered variant="dark">
        <thead>
          <tr>
            <th>Market Name</th>
            <th>Stake Price</th>
            <th>Bet Status</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {allMarketData?.map((market) => (
            <tr key={market.id}>
              <td>{market.marketName}</td>
              <td
                title="Edit"
                style={{ cursor: "pointer" }}
                onClick={() => handleOpenEditBetModal(market.id)}
              >
                {market.minStack} - {market.maxStack}
              </td>
              <td>
                {" "}
                <Form.Check
                  type="switch"
                  label={market.betLock ? "Bet Disabled" : "Bet Enabled"}
                  checked={market.betLock}
                  onChange={(e) =>
                    handleSwitchChange(e, market, "Market_BetStatus")
                  }
                />
              </td>
              <td>
                <Form.Check
                  type="switch"
                  label={market.enabled ? "Enable" : "Disable"}
                  onChange={(e) =>
                    handleSwitchChange(e, market, "Market_Enable")
                  }
                  checked={market.enabled}
                />
              </td>
              <td>
                {isEmpty(market.isMarketSettled) || !market.isMarketSettled ? (
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      handleOpenMarketSettelModal(market, eventId, "settle")
                    }
                  >
                    Settle
                  </button>
                ) : (
                  <button
                    className="btn btn-outline-danger"
                    onClick={() =>
                      handleOpenMarketSettelModal(market, eventId, "rollback")
                    }
                  >
                    Roll Back
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        activePage={activePage}
        itemsCountPerPage={20}
        totalItemsCount={totalPageCount}
        pageRangeDisplayed={20}
        onChange={handlePageChange}
      />

      <ConfirmModal
        show={showConfrimMatchModal}
        handleClose={handleCloseConfirmMatchModal}
        handleConfirm={matchEnableDisabledHandler}
      >
        <h5>
          Are you sure you want to{" "}
          <strong>{confirmMatchDetails?.confirmValue}</strong> this{" "}
          <strong>{confirmMatchDetails?.matchName}</strong>?
        </h5>
      </ConfirmModal>
      <CustomModal
        show={showEditBetModal}
        handleClose={handleCloseEditBetModal}
        onSubmit={onSubmitBetHandler}
        title="Edit Match Bet"
        isError={errorMessage}
      >
        <Form>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Min Bet</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter min bet value"
              name="minMarket"
              value={editBetData?.minMarket}
              onChange={handleMarketEditChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Max Bet</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter max bet value"
              name="maxMarket"
              value={editBetData?.maxMarket}
              onChange={handleMarketEditChange}
            />
          </Form.Group>
        </Form>
        {errorMessage && <p className="text-danger">{errorMessage}</p>}
      </CustomModal>
      <CustomModal
        show={showMarketSettelModal}
        handleClose={handleCloseMarketSettelModal}
        onSubmit={settleMarketFancyHandler}
        title="Market Settelment"
        isError={errorMessage}
        settelmentData={settelmentData}
        fromSettel
        isRollback={marketSettlmentType === "rollback"}
      >
        {marketSettlmentType !== "rollback" ? (
          <Form>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              {settelmentData?.marketName
                ?.toLowerCase()
                .includes(marketNameTemp.toLowerCase()) ? (
                <FloatingLabel controlId="floatingSelect" label="Select Winner">
                  <Form.Select
                    aria-label="Floating label select example"
                    onChange={handleChangeWinner}
                  >
                    <option>Please select a winner</option>
                    {settelmentData?.subMarketInfo?.map((item) => (
                      <option
                        value={`${item.selectionId}-${item.runnerName}`}
                        key={item.selectionId}
                      >
                        {item.runnerName}
                      </option>
                    ))}
                    <option value="abandoned">Abandoned</option>
                    <option value="tie">Tie</option>
                  </Form.Select>
                </FloatingLabel>
              ) : (
                // <Form.Check
                //   type="switch"
                //   id="custom-switch"
                //   label={"Session Passed"}
                //   onChange={(e) => handleSwitchSettelChange(e)}
                // />
                <>
                  <Form.Check
                    inline
                    label="Abandoned"
                    name="matchResultType"
                    type="radio"
                    id={`inline-radio-1`}
                    value={matchResultType?.abandoned}
                    onChange={(e) => handleChangeRadio(e, "abandoned")}
                  />
                  <Form.Check
                    inline
                    label="Tie"
                    name="matchResultType"
                    type="radio"
                    id={`inline-radio-2`}
                    value={matchResultType?.tie}
                    onChange={(e) => handleChangeRadio(e, "tie")}
                  />
                  <Form.Check
                    inline
                    label="Final Result"
                    name="matchResultType"
                    type="radio"
                    id={`inline-radio-3`}
                    value={matchResultType?.finalResult}
                    onChange={(e) => handleChangeRadio(e, "finalResult")}
                  />
                  {matchResultType?.finalResult && (
                    <FloatingLabel
                      controlId="floatingSelect"
                      label="Select Winner"
                    >
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={handleChangeWinner}
                      >
                        <option>Please select a winner</option>
                        {settelmentData?.subMarketInfo?.map((item) => (
                          <option
                            value={`${item.selectionId}-${item.runnerName}`}
                            key={item.selectionId}
                          >
                            {item.runnerName}
                          </option>
                        ))}
                        <option value="abandoned">Abandoned</option>
                        <option value="tie">Tie</option>
                      </Form.Select>
                    </FloatingLabel>
                  )}
                </>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Comment</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter comment"
                name="comment"
                value={settelmentData?.comment}
                onChange={handleChangeSettel}
              />
            </Form.Group>
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
          </Form>
        ) : (
          <h5>Are you sure you want to rollback</h5>
        )}
      </CustomModal>
      <ToastMessage
        show={showToast}
        close={handleCloseToast}
        message={toastMessage}
        type="Success"
        title=""
      />
    </>
  );
}
