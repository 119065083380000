import axios from "axios";
import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import UserProvider from "./Context/UserProvider";
import Routes from "./routes";
import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
import toast, { Toaster } from "react-hot-toast";
const fpPromise = FingerprintJS.load({
  token: "UKZURBeqMsPP0IkxNmJj",
});
function App() {
  return (
    <div className="App" id="app">
      <BrowserRouter basename="/">
        <UserProvider>
          <Routes />
        </UserProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
