import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/style/app.scss";
import "react-datepicker/dist/react-datepicker.css";
import toast, { Toaster } from "react-hot-toast";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import AllMarketProvider from "./Context/AllMarketProvider";


ReactDOM.render(
  <React.StrictMode>
    <AllMarketProvider>
      <Toaster />
      {/*  <ToastContainer /> */}
      <App />
    </AllMarketProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
