import { PORT } from "./port";
const CRICKET_PORT = 8001;
const TENNIS_PORT = 8002;
const SOCCER_PORT = 8002;
const BASE_PORT = 8000;
const IS_PROD = process.env.REACT_APP_API_URL.startsWith("api");
export const CRICKET_BASE_URL = IS_PROD
  ? `https://${process.env.REACT_APP_API_URL}/cm/v1/cricket`
  : `http://${process.env.REACT_APP_API_URL}:${CRICKET_PORT}/api/cricket`;
export const TENNIS_BASE_URL = IS_PROD
  ? `https://${process.env.REACT_APP_API_URL}/tas/v1/tennis`
  : `http://${process.env.REACT_APP_API_URL}:${TENNIS_PORT}/api/tennis`;
export const SOCCER_BASE_URL = IS_PROD
  ? `https://${process.env.REACT_APP_API_URL}/tas/v1/soccer`
  : `http://${process.env.REACT_APP_API_URL}:${SOCCER_PORT}/api/soccer`;
export const HORCE_RACING_BASE_URL = `http://${process.env.REACT_APP_API_URL}:${PORT}/hac/api`;
export const BASE_URL = IS_PROD
  ? `https://${process.env.REACT_APP_API_URL}/aam/v1`
  : `http://${process.env.REACT_APP_API_URL}:${BASE_PORT}/api`;
export const autoSettleFor = ["Over By Over", "Normal"];
